/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageIndustrias/pageEcommerce/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageIndustrias/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 
import Experience from "@components/pageIndustrias/pageEcommerce/experience/experience"
import { TitleUnderlineRightOne, TitleUnderlineRightTwo } from "@components/pageIndustrias/pageEcommerce/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageIndustrias/pageEcommerce/titleUnderline/titleUnderlineTestimony"
import { TitleUnderlineNavbarVerticalOne, TitleUnderlineNavbarVerticalTwo } from "@components/pageIndustrias/pageEcommerce/titleUnderline/titleUnderlineNavbarVertical"
import monedas from "@components/sharedComponents/testimonies/images/iconos/monedas.svg"
import factura from "@components/sharedComponents/testimonies/images/iconos/factura.svg"
import billetera from "@components/sharedComponents/testimonies/images/iconos/billetera.svg"
import campana from "@components/sharedComponents/testimonies/images/iconos/campana.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import NavbarVertical from "@components/sharedComponents/navbarVertical/navbarVertical"
import ImageVerticalThree from "@components/pageIndustrias/pageEcommerce/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFour from "@components/pageIndustrias/pageEcommerce/images/imagesNavbarVertical/imageFour.webp"


const TemplatePageEcommerce = ({ location }) => {

  //Video youtube intro
  const [videoIntro, setVideoIntro] = useState(false)
  const openVideoIntro = value => {
    setVideoIntro(value)
  }

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true


  const titleAccordionRight = <p>
    <TitleUnderlineRightOne underline="Aumenta tu ticket" /> <TitleUnderlineRightTwo underline="de venta" /> con experiencias que generan recordación a los clientes en cada recorrido de compra
  </p>
  const titleTestimony = <p>
    Ecommerce en todo el mundo prefieren Beex para mejorar sus procesos de <TitleUnderlineTestimony underline="marketing y ventas" />
  </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return billetera
      case 1:
        return factura
      case 2:
        return monedas
      case 3:
        return campana
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Multicartera"
      case 1:
        return "Castigada"
      case 2:
        return "Mora temprana"
      case 3:
        return "Preventiva"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  const titleNavbarVertical = <p>
    Utiliza la API de WhatsApp Business para <TitleUnderlineNavbarVerticalOne underline="conectar mejor" /> con tus consumidores
  </p>

  const objOne = {
    tab: "1",
    title: "Envío de ofertas y promociones",
    text: (
      <p>
        Tanto para fechas clave de descuentos o para nuevos lanzamientos de productos, fideliza a tus clientes por WhatsApp enviando mensajes únicos y personalizados de forma masiva. Todo esto en simples pasos, gracias a las integraciones nativas de nuestra solución.
      </p>
    ),
    image: ImageVerticalThree,
  }
  const objTwo = {
    tab: "1",
    title: "Envío de ofertas y promociones",
    text: (
      <p>
        Tanto para fechas clave de descuentos o para nuevos lanzamientos de productos, fideliza a tus clientes por WhatsApp enviando mensajes únicos y personalizados de forma masiva. Todo esto en simples pasos, gracias a las integraciones nativas de nuestra solución.
      </p>
    ),
    image: ImageVerticalFour,
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        
        <Header
          path="/soluciones/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {videoIntro ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideoIntro(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/3Uy-eKkHb64?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/I0oupPyW3ew?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} openVideo={openVideoIntro} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Comercios online han logrado fidelizar mejor gracias a experiencias personalizadas con Beex" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Automatiza los procesos de compra de tu ecommerce ahora mismo"
            text="Potencia las operaciones principales de tu comercio electrónico con ayuda de un experto."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Testimonies buttonSilder={true} data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="¿Quieres automatizar la atención y ventas de tu e-commerce?"
            text="Contamos con expertos que se adaptan a tus necesidades y te ayudarán a escalar tus operaciones."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <NavbarVertical dataOne={data.navbarVerticalRight} dataTwo={data.navbarVerticalLeft}
            dataSilder={data.navbarVerticalSilder}
            objOne={objOne}
            objTwo={objTwo}
            title={titleNavbarVertical}
            description={<p className="container-navbar-vertical-description">Conecta con tus usuarios con la aplicación de mensajería más utilizada a nivel mundial</p>} />

          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageEcommerce
